import styled, { css } from 'styled-components';

type Environments = 'LOCAL' | 'DEV' | 'PRE' | 'PRO';

export interface DevBarProps {}

const DevBar = ({}: DevBarProps) => {
  const environment = process.env.NEXT_PUBLIC_ENVIRONMENT as Environments;
  const version = process.env.NEXT_PUBLIC_IMAGE_VERSION;
  const revision = process.env.NEXT_PUBLIC_IMAGE_SOURCE_REVISION;
  const repoSrc = `https://gitlab.com/rungie/mito-main/-/commit/${revision}`;

  const shouldDisplayDevBar = environment === 'DEV' || environment === 'PRE';

  if (shouldDisplayDevBar)
    return (
      <Root>
        <Text target="_blank" href={repoSrc}>
          <Environment>{environment}</Environment>
          {version && <Subtext>{version}</Subtext>}
        </Text>
        <Bg />
      </Root>
    );

  return null;
};

export default DevBar;

/** Styled components */

const Root = styled.div`
  ${({ theme }) => css`
    position: absolute;
    top: 0;
    left: 0;
    width: 100px;
    height: 100px;
    z-index: 999;
  `}
`;

const Bg = styled.div`
  ${({ theme }) => css`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: rotate(45deg) translate(-70%, 0%);
    z-index: 1;
    background: ${theme.colors.highlight};
    color: ${theme.colors.textColor};
  `}
`;

const Text = styled.a`
  ${({ theme }) => css`
    display: block;
    position: relative;
    z-index: 2;
    transform: rotate(-45deg) translate(-25%, -35%);
    text-align: center;
  `}
`;

const Environment = styled.div`
  ${({ theme }) => css`
    font-size: 12px;
    font-weight: bold;
  `}
`;

const Subtext = styled.div`
  ${({ theme }) => css`
    font-size: 9px;
  `}
`;
